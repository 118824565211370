import { MouseEvent, RefObject, ReactNode } from "react";

import { AutofillIndicator } from "./autofill-components";
import {
  Page,
  Section,
  SectionButtons,
  SectionHeading,
  PageWrapper,
  SectionForm,
  SectionGap,
  SectionToast,
} from "./section-components";
import { ConnectionBanner } from "./section-components/connection-banner";
import {
  AutoFillStatus,
  ConnectionItem,
  SectionErrors,
  SectionItem,
  TeamItem,
  ToastContent,
} from "../types";

export type IntakeSectionProps = {
  team: TeamItem;
  connection?: ConnectionItem;
  autoFillSection?: SectionItem;
  sections: SectionItem[];
  selected: SectionItem;
  sidebar: boolean;
  onPreviousButton?: () => void;
  onNextButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmitButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: () => void;
  onReset?: () => void;
  children?: ReactNode;
  errors?: SectionErrors;
  errorsRef?: RefObject<HTMLDivElement>;
  submitError?: string;
  isForm?: boolean;
  previewErrorToggle?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  showConnectionBanner?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  retrySubmitResponses: () => void;
  autoFillStatus?: AutoFillStatus;
  toast?: ToastContent;
  closeToast?: () => void;
  loading?: boolean;
  aiFillFromMatterInfo?: boolean;
};

const noop = () => {};

export const IntakeSection = ({
  team,
  connection,
  autoFillSection,
  sections,
  selected,
  sidebar,
  onPreviousButton,
  onNextButton,
  onSubmitButton,
  onSubmit,
  onReset,
  children,
  submitError,
  errors,
  errorsRef,
  isForm = true,
  previewErrorToggle,
  showConnectionBanner,
  retrySubmitResponses,
  autoFillStatus,
  toast,
  closeToast,
  loading,
  aiFillFromMatterInfo,
}: IntakeSectionProps) => {
  const selectedIndex = sections.findIndex((s) => s.uri === selected?.uri);

  const buttons = {
    onPrevious:
      selectedIndex > 0 || (selectedIndex === 0 && autoFillSection)
        ? onPreviousButton || noop
        : undefined,
    onNext: sections.length > 1 ? onNextButton || noop : undefined,
    onSubmit: sections.length === 1 ? onSubmitButton || noop : undefined,
  };

  const isLoadingBeforeAutoFill = loading && aiFillFromMatterInfo;
  const autoFillInProgress = autoFillStatus === "filling";

  return (
    <PageWrapper sidebar={sidebar}>
      <ConnectionBanner
        connectionError={connection?.status === "error"}
        showConnectionBanner={showConnectionBanner}
        retry={retrySubmitResponses}
      />
      <SectionToast
        content={toast}
        onClose={() => {
          if (closeToast) closeToast();
        }}
        timeoutInSec={3}
      />
      {(autoFillStatus || isLoadingBeforeAutoFill) && (
        <AutofillIndicator
          status={autoFillStatus}
          loading={isLoadingBeforeAutoFill}
        />
      )}
      <Page>
        <Section>
          <SectionForm onSubmit={onSubmit} onReset={onReset} isForm={isForm}>
            <SectionButtons
              isForm={isForm}
              team={team}
              hasError={submitError}
              {...buttons}
              disabled={{
                submit: autoFillInProgress || isLoadingBeforeAutoFill,
                back: autoFillInProgress || isLoadingBeforeAutoFill,
                next: autoFillInProgress || isLoadingBeforeAutoFill,
              }}
            >
              <SectionHeading
                section={selected}
                errors={errors}
                errorsRef={errorsRef}
                hideErrors={previewErrorToggle?.[0]}
              />
              <SectionGap />
              {children}
              <SectionGap />
            </SectionButtons>
          </SectionForm>
        </Section>
      </Page>
    </PageWrapper>
  );
};
