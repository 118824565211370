import styled from "@emotion/styled";

import { formatValue, UploadedFile } from "@smart/bridge-types-basic";
import { Displayer } from "@smart/itops-editor-dom";
import {
  entriesOf,
  localTimeZoneCode,
  specialChars,
} from "@smart/itops-utils-basic";

import { FieldComponentType, fieldFallbackLabel, FieldItem } from "../../types";
import { displayAppointmentValues } from "../../utils";
import { Indicator } from "../field-components";

const SummaryFieldWrapper = styled.div`
  h4 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  h3 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }
`;

export type SummaryFieldProps = {
  field: FieldItem;
  value: any;
};

export const SummaryField = ({
  field: { label, mandatory, type, options, description },
  value,
}: SummaryFieldProps) => {
  const displayFieldValue = () => {
    if (type === "info") return null;

    if (type === "file" && Array.isArray(value)) {
      return value?.length
        ? value.map((file: UploadedFile) => (
            <p key={file.downloadUrl}>{file.fileName}</p>
          ))
        : specialChars.enDash;
    }

    if (type === "appointment") {
      const date = displayAppointmentValues({
        startTime: value?.startTime,
        endTime: value?.endTime,
        timezone: value?.timezone,
      });
      return (
        <div>
          <p data-testid="booking-message">
            {value && value.status !== "invalid" && value.bookedStaff?.length
              ? `With ${[
                  value.bookedStaff[0].firstName,
                  value.bookedStaff[0].middleName,
                  value.bookedStaff[0].lastName,
                ]
                  .filter(Boolean)
                  .join(
                    " ",
                  )} at ${date.time} on ${date.day} (${value?.timezone || localTimeZoneCode})`
              : specialChars.enDash}
          </p>
        </div>
      );
    }

    if (type === "payment") {
      const getPaymentDisplayText = () => {
        const charge = value?.charges && entriesOf(value.charges)[0]?.[1];
        if (charge?.status === "FAILED") {
          const errorMessage = charge.smokeballResponse?.failure?.message;
          return `Failed to process the payment${errorMessage ? ` - ${errorMessage}` : ""}`;
        }
        if (charge?.status === "SUCCESSFUL") return "Payment completed";
        if (value?.chargeRequest)
          return (
            value?.chargeRequest?.description ||
            "Payment details have been saved."
          );
        return specialChars.enDash;
      };

      return <p>{getPaymentDisplayText()}</p>;
    }

    if (type === "multilineText") {
      const paragraphs = formatValue(value, type, options)
        ?.trim()
        .split("\n\n")
        .filter(Boolean);

      return paragraphs?.length ? (
        paragraphs.map((text) => <p>{text}</p>)
      ) : (
        <p>
          <span>{specialChars.enDash}</span>
        </p>
      );
    }

    const formatted = formatValue(value, type, options).trim();
    return (
      <p>
        <span>{formatted || specialChars.enDash}</span>
      </p>
    );
  };

  return (
    <SummaryFieldWrapper>
      <Displayer
        paragraphTag="h4"
        value={label}
        fallback={fieldFallbackLabel[type as FieldComponentType]}
        hintElement={mandatory && <Indicator />}
      />
      {description && <Displayer paragraphTag="h3" value={description} />}
      {displayFieldValue()}
    </SummaryFieldWrapper>
  );
};
